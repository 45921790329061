<template>
  <div class="wrap">
    <div class="nav_bar">
      <van-icon
        name="arrow-left"
        size="20"
        style="position: absolute; left: 18px; top: 24px"
        @click="onClickLeft"
        v-show="!backIcon"
      />
      <span style="font-size: 20px; color: #323233; font-weight: bold"
        >在院患者</span
      >
     
    </div>
     <span class="logOut" @click="logOut">退出</span>
    <van-notice-bar mode="closeable" color="#333333" background="#FEF0E7">
      <div class="tip_box">
        <div class="box_1">特级护理</div>
        <div class="box_2">一级护理</div>
        <div class="box_3">二级护理</div>
        <div class="box_4">三级护理</div>
      </div>
    </van-notice-bar>
    <div class="search_box">
      <div class="left_box">
        <van-dropdown-menu active-color="#1989fa">
          <van-dropdown-item
            v-model="deptCode"
            :options="deptList"
            @change="onConfirm"
            :disabled="loading"
          />
        </van-dropdown-menu>
      </div>
      <div class="right_box">
        <van-search
          v-model="patientName"
          placeholder="请输入搜索关键词"
          @search="onSearch"
        />
      </div>
    </div>
    <div class="loading">
      <van-loading type="spinner" v-show="loading" />
    </div>
    <div class="total" v-if="total > 0 && loading == false">
      共{{ total }}位病人
    </div>

    <div class="content">
      <div class="content_box" v-for="(item, i) in list" :key="i">
        <div class="top">
          <div class="top_box">
            <div class="top_1">
              <div
                class="num"
                :style="{
                  background:
                    item.nursingClassName == '特级护理'
                      ? '#FE5656'
                      : item.nursingClassName == '一级护理'
                      ? '#A18AEA'
                      : item.nursingClassName == '二级护理'
                      ? '#47ABFF'
                      : item.nursingClassName == '三级护理'
                      ? '#16B78C'
                      : '#16B78C',
                }"
              >
                {{ item.bedNo }}
              </div>
              <div class="info_box">
                <div class="name" @click="onClickInfo(item, 0)">
                  {{ item.name }}
                </div>
                <div class="sex">{{ item.sex }} {{ item.age }}岁</div>
              </div>
            </div>
            <div class="top_2">
              <div
                class="danger"
                v-if="item.patientStatusName == '危'"
                @click="onClickInfo"
              >
                危
              </div>
              <div
                class="heavy"
                v-if="item.patientStatusName == '重'"
                @click="onClickInfo"
              >
                重
              </div>
              <div
                class="general"
                v-if="item.patientStatusName == '一般'"
                @click="onClickInfo"
              >
                一般
              </div>

              <a :href="'tel:' + item.phoneNumber">
                <van-icon
                  name="phone-circle"
                  style="color: #1578f9"
                  v-if="item.phoneNumber"
                />
                <!-- @click="getPhone(item.phoneNumber)" -->
              </a>
            </div>
          </div>
          <div class="doctor_box">
            <div><span style="color: #999">住院号：</span>{{ item.inpNo }}</div>
            <div>
              <span style="color: #999" v-if="item.doctorInCharge">医生：</span>
              {{ item.doctorInCharge }}
            </div>
          </div>

          <div style="font-size: 14px; padding: 0px 0px 16px 30px">
            <span style="color: #999" v-if="item.deptAdmissionTo"
              >住院病区：</span
            >{{ item.deptAdmissionTo }}
          </div>
          <div class="doctor_box">
            <!-- <div><span style="color:#999" v-if="item.totalCosts">总费用：</span>{{ item.totalCosts }}
                </div> -->
            <div>
              <span style="color: #999" v-if="item.chargeType">费别：</span
              >{{ item.chargeType }}
            </div>
            <div>
              <span style="color: #999" v-if="item.nursingClassName"
                >护理类型：</span
              >{{ item.nursingClassName }}
            </div>
          </div>
          <div style="font-size: 14px; padding: 0px 0px 16px 30px; color: #999">
            入院日期：<span style="color: #feaf85">{{
              item.admissionDateTime
            }}</span>
          </div>
        </div>
        <div class="icon_box">
          <div
            style="text-align: center"
            class="iconBox"
            @click="onClickInfo(item, 1)"
          >
            <svg-icon
              font="25"
              class="mr6"
              :type="
                item.medicalRecordTypes['检验报告'] > 0
                  ? 'icon-ico_yishenggongzuozhan_zhongyililiaojianzhen-copy'
                  : 'icon-ico_yishenggongzuozhan_zhongyililiaojianzhen'
              "
            >
            </svg-icon>
           
            <div
              class="icon_text"
              :style="{
                color:
                  item.medicalRecordTypes['检验报告'] > 0 ? '#1578f9' : '#999',
              }"
            >
              检验报告
            </div>
          </div>
          <div
            style="text-align: center"
            class="iconBox"
            @click="onClickInfo(item, 2)"
          >
            <svg-icon
              font="25"
              class="mr6"
              :type="
                item.medicalRecordTypes['病程记录'] > 0
                  ? 'icon-ico_yiyuanguanjia_tiaochawenjuan-copy'
                  : 'icon-ico_yiyuanguanjia_tiaochawenjuan'
              "
              style="color: #323233"
            ></svg-icon>
          
            <div
              class="icon_text"
              :style="{
                color:
                  item.medicalRecordTypes['病程记录'] > 0 ? '#1578f9' : '#999',
              }"
            >
              病程记录
            </div>
          </div>
          <div
            style="text-align: center"
            class="iconBox"
            @click="onClickInfo(item, 3)"
          >
            <svg-icon
              font="25"
              :type="
                item.medicalRecordTypes['长期医嘱'] > 0
                  ? 'icon-ico_yiyuanguanjia_Szongheyiliaoganyu-copy'
                  : 'icon-ico_yiyuanguanjia_Szongheyiliaoganyu'
              "
            >
            </svg-icon>
         
            <div
              class="icon_text"
              :style="{
                color:
                  item.medicalRecordTypes['长期医嘱'] > 0 ? '#1578f9' : '#999',
              }"
            >
              长期医嘱
            </div>
          </div>
          <div
            style="text-align: center"
            class="iconBox"
            @click="onClickInfo(item, 4)"
          >
            <svg-icon
              font="25"
              :type="
                item.medicalRecordTypes['体温单'] > 0
                  ? 'icon-ico_xitongshezhi_bucaopeizhi-copy'
                  : 'icon-ico_xitongshezhi_bucaopeizhi'
              "
            >
            </svg-icon>
           
            <div
              class="icon_text"
              :style="{
                color:
                  item.medicalRecordTypes['体温单'] > 0 ? '#1578f9' : '#999',
              }"
            >
              体温单
            </div>
          </div>
          <div
            style="text-align: center"
            class="iconBox"
            @click="onClickInfo(item, 5)"
          >
            <svg-icon
              font="25"
              :type="
                item.medicalRecordTypes['手术记录'] > 0
                  ? 'icon-ico_yishenggongzuozhan_jiankangmeilidangan-copy'
                  : 'icon-ico_yishenggongzuozhan_jiankangmeilidangan'
              "
            >
            </svg-icon>
           
            <div
              class="icon_text"
              :style="{
                color:
                  item.medicalRecordTypes['手术记录'] > 0 ? '#1578f9' : '#999',
              }"
            >
              手术记录
            </div>
          </div>
        </div>
        <div class="tips_danger" v-if="false">
          <svg-icon font="10" type="icon-yujing"> </svg-icon>
          危急
        </div>
      </div>
    </div>
    <!-- <div class="pagination" v-show="list.length > 0">
      <van-pagination
        v-model="pageNumber"
        :page-count="pages"
        mode="simple"
        @change="changePagination"
      />
    </div> -->

    <div v-show="list.length == 0 && !loading" class="no-more-data">
      暂无数据
    </div>
    <van-action-sheet
      v-model="showPicker"
      :actions="actions"
      cancel-text="取消"
      close-on-click-action
    />
  </div>
</template>
<script>
import SvgIcon from "@/components/SvgIcon.vue";
import { getPatientInfos, getDeptList } from "@/api/patients";
import dateUtil from "@/api/dateUtil.js";
import { setStore, getStore, removeStore } from "@/utils/localStorage.js";

export default {
  data() {
    return {
      patientName: "",
      loading: false,
      showEmpty: false,
      showPicker: false,
      finished: false,
      isScrolling: false,
      scrollBottom: 100,
      refreshing: false,
      noMoreData: false,
      finishedText: "",
      list: [],
      actions: [],
      deptCode: "",
      total: 0, // 总页数
      pageNumber: getStore({ name: "page" }) || 1, // 请求第几页数
      pageSize: 10, // 请求每页条数
      pages: 0,
      deptList: [],
      backIcon: localStorage.getItem("routerHistory"),
    };
  },
  components: { SvgIcon },
  created() {
    if (getStore({ name: "deptCode" })) {
      this.deptCode = getStore({ name: "deptCode" });
    }
    if (getStore({ name: "page" })) {
      this.pageNumber = getStore({ name: "page" });
    }
  },
  mounted() {
    // 修改父元素样式
    document.getElementById("container").style.background = "#F6F6F6";
    this.getDeptListInfo();
  },
  methods: {
    logOut(){
   this.$router.replace("/login");
},
    getList() {
      this.loading = true;
      getPatientInfos(
        // this.pageNumber,
        // this.pageSize,
        this.deptCode,
        this.patientName
      )
        .then((res) => {
          if (res.data.code == 200) {
            console.log(res,'RES')
            this.loading = false;
            this.total = res.data.data.total;
            this.pages = res.data.data.pages;
            let result = res.data.data.records || [];
            this.list = result;
            this.list.map((item) => {
              if (item.admissionDateTime) {
                item.admissionDateTime = dateUtil.formatDate3(
                  item.admissionDateTime
                );
              }
            });
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    // 获取科室数据
    getDeptListInfo() {
      this.pageNumber = getStore({ name: "page" }) || 1;
      getDeptList().then((res) => {
        if (res.data.code == "200") {
          res.data.data.map((item) => {
            const obj = {
              text: item.name,
              value: item.id,
            };
            this.deptList.push(obj);
          });
        }

        if (this.$route.query.deptCode) {
          this.deptCode = this.$route.query.deptCode;
          this.getList();
        } else if (getStore({ name: "deptCode" })) {
          this.deptCode = getStore({ name: "deptCode" });
          this.getList();
        } else {
          if (this.deptList.length > 0) {
            this.deptCode = this.deptList[0].value;

            this.getList();
          }
        }
      });
    },
    // 搜索
    onSearch() {
      this.pageNumber = 1;
      this.list = [];
      this.loading = true;
      this.finished = false;
      this.getList();
    },
    // 获取手机号
    getPhone(phoneNumber) {
      this.actions = [];
      this.actions.push({ name: phoneNumber });
      this.showPicker = true;
    },
    onClickLeft() {
      // this.$router.back()
      this.$router.push({ path: "/menu" });
    },
    onClickInfo(item, type) {
      setStore({ name: "page", content: this.pageNumber });
      sessionStorage.setItem("one", item.newMessageToDay["检验报告"]);
      sessionStorage.setItem("two", item.newMessageToDay["病程记录"]);
      sessionStorage.setItem("three", item.newMessageToDay["长期医嘱"]);
      sessionStorage.setItem("four", item.newMessageToDay["体温单"]);
      sessionStorage.setItem("five", item.newMessageToDay["手术记录"]);
      // return;
      if (type == 0) {
        this.$router.push({
          path: "/patientsInfo",
          query: {
            patientId: item.patientId,
            visitId: item.visitId,
            deptCode: item.deptCode,
          },
        });
      }
      if (type == 1) {
        if (item.medicalRecordTypes["检验报告"] > 0) {
          sessionStorage.removeItem("one");
          this.$router.push({
            path: "/patientsInfo",
            query: {
              patientId: item.patientId,
              visitId: item.visitId,
              deptCode: item.deptCode,
              medicalRecordTypes: "检验报告",
            },
          });
        } else {
          return;
        }
      }
      if (type == 2) {
        if (item.medicalRecordTypes["病程记录"] > 0) {
          sessionStorage.removeItem("two");
          this.$router.push({
            path: "/patientsInfo",
            query: {
              patientId: item.patientId,
              visitId: item.visitId,
              deptCode: item.deptCode,
              medicalRecordTypes: "病程记录",
            },
          });
        } else {
          return;
        }
      }
      if (type == 3) {
        if (item.medicalRecordTypes["长期医嘱"] > 0) {
          sessionStorage.removeItem("three");
          this.$router.push({
            path: "/patientsInfo",
            query: {
              patientId: item.patientId,
              visitId: item.visitId,
              deptCode: item.deptCode,
              medicalRecordTypes: "长期医嘱",
            },
          });
        } else {
          return;
        }
      }
      if (type == 4) {
        if (item.medicalRecordTypes["体温单"] > 0) {
          sessionStorage.removeItem("four");
          this.$router.push({
            path: "/patientsInfo",
            query: {
              patientId: item.patientId,
              visitId: item.visitId,
              deptCode: item.deptCode,
              medicalRecordTypes: "体温单",
            },
          });
        } else {
          return;
        }
      }
      if (type == 5) {
        if (item.medicalRecordTypes["手术记录"] > 0) {
          sessionStorage.removeItem("five");
          this.$router.push({
            path: "/patientsInfo",
            query: {
              patientId: item.patientId,
              visitId: item.visitId,
              deptCode: item.deptCode,
              medicalRecordTypes: "手术记录",
            },
          });
        } else {
        }
      }
    },
    // changePagination(val) {
    //   this.pageNumber = val;
    //   this.list = [];
    //   this.getList();
    // },
    onConfirm(value) {
      this.noMoreData = false;
      this.deptCode = "";
      this.list = [];
      this.pageNumber = 1;
      this.total = 0;
      this.deptCode = value;
      removeStore({ name: "page" });
      setStore({ name: "deptCode", content: this.deptCode });
      this.getList();
      // this.deptitle = this.deptlist[value].text
    },
  },
};
</script>
<style lang="less" scoped>
.wrap {
  padding-top: 20px;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background: #f6f6f6;
  box-sizing: border-box;
  padding-bottom: 20px;
  position: relative;
  .nav_bar {
    text-align: center;
    color: #323233;
 
  }

  .tip_box {
    display: flex;

    .box_1 {
      margin-right: 16px;
    }

    .box_2 {
      margin-right: 16px;
    }

    .box_3 {
      margin-right: 16px;
    }

    .box_4 {
      margin-right: 16px;
    }

    .box_1:before {
      width: 10px;
      height: 10px;
      float: left;
      content: "";
      display: block;
      margin-right: 5px;
      background: #fe5656;
      margin-top: 6px;
    }

    .box_2:before {
      width: 10px;
      height: 10px;
      float: left;
      content: "";
      display: block;
      margin-right: 5px;
      background: #a18aea;
      margin-top: 6px;
    }

    .box_3:before {
      width: 10px;
      height: 10px;
      float: left;
      content: "";
      display: block;
      margin-right: 5px;
      background: #47abff;
      margin-top: 6px;
    }

    .box_4:before {
      width: 10px;
      height: 10px;
      float: left;
      content: "";
      display: block;
      margin-right: 5px;
      background: #16b78c;
      margin-top: 6px;
    }
  }
.logOut{
  position: absolute;
  right: 15px;
  top: 23px;
  font-size: 16px;
  color: #1989fa;
  letter-spacing: 2px;
}
  .search_box {
    margin-top: 20px;
    padding: 0 20px;
    display: flex;
    align-items: center;

    .right_box {
      width: 80%;
    }
  }

  .total {
    text-align: center;
    color: #999;
    font-size: 14px;
    margin: 15px 0;
  }

  .content {
    margin: 0 16px;

    .content_box {
      // width: 343px;
      // height: 308px;
      background: #ffffff;
      box-shadow: 0px 1px 2px 0px rgba(7, 2, 14, 0.07);
      border-radius: 10px;
      margin-bottom: 17px;

      .top {
        border-bottom: 1px solid #f2f2f2;

        .top_box {
          display: flex;
          justify-content: space-between;
          padding: 20px 25px 26px 30px;

          .top_1 {
            display: flex;

            .num {
              width: 43px;
              height: 43px;
              background: #fe5656;
              border-radius: 10px;
              font-size: 21px;
              line-height: 43px;
              text-align: center;
              color: #ffffff;
              margin-right: 10px;
            }

            .info_box {
              .name {
                font-weight: bold;
                color: #333333;
                font-size: 19px;
                margin-top: -3px;
              }

              .sex {
                font-size: 14px;
                // font-weight: bold;
                color: #999999;
                margin-top: 5px;
              }
            }
          }

          .top_2 {
            display: flex;

            .danger {
              width: 24px;
              height: 24px;
              line-height: 24px;
              text-align: center;
              font-size: 14px;
              color: #fff;
              background: #f12c20;
              border-radius: 50%;
              // font-weight: bold;
              margin-right: 10px;
            }

            .heavy {
              width: 24px;
              height: 24px;
              line-height: 24px;
              text-align: center;
              font-size: 14px;
              color: #fff;
              background: #fcb43d;
              border-radius: 50%;
              // font-weight: bold;
              margin-right: 10px;
            }

            .general {
              width: 40px;
              height: 21px;
              line-height: 21px;
              text-align: center;
              font-size: 14px;
              color: #fff;
              background: #47abff;
              border-radius: 10px;
              // font-weight: bold;
              margin-right: 10px;
              padding: 2px;
            }
          }
        }

        .doctor_box {
          display: flex;
          justify-content: space-between;
          font-size: 14px;
          padding: 0px 60px 16px 30px;
        }
      }

      .icon_box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 24px 15px 24px;

        .icon_text {
          //   display: flex;
          // justify-content: space-between;
          // align-items: center;
          font-size: 14px;
          // color: #999;
          color: #1578f9;
          margin-top: 8px;
          //  padding: 0px 16px 20px 16px;
        }
      }

      .tips_danger {
        text-align: center;
        color: #fff;
        background-color: #f12c20;
        font-size: 19px;
        font-weight: bold;
        padding: 15px 0;
        border-radius: 0px 0px 10px 10px;
      }

      // .icon_text{
      //       display: flex;
      //     justify-content: space-between;
      //     align-items: center;
      //     font-size: 9px;
      //     color: #999;
      //      padding: 0px 16px 20px 16px;
      // }
    }
  }
}

/deep/.van-notice-bar {
  padding-right: 14px !important;
  margin-top: 15px !important;
}

/deep/.van-search {
  padding: 0px 14px !important;
  border-radius: 10px !important;
}

/deep/.van-search__content {
  background: #fff !important;
}

/deep/.left_box {
  margin-right: 20px;
}

/deep/.van-icon-phone-circle {
  color: #1578f9 !important;
  font-size: 25px;
}

/deep/.van-dropdown-menu__bar {
  background-color: #f6f6f6 !important;
  box-shadow: none !important;
}
.not {
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #999;
  margin-bottom: 10px;
}
.loading {
  width: 10%;
  margin: 0 auto;
}
// .van-field__body
.iconBox {
  position: relative;
}

.loadings {
  text-align: center;
  margin-top: 10px;
  color: #9999;
}
.no-more-data {
  text-align: center;
  color: #9999;
  margin-top: 10px;
}
.pagination {
  width: 90%;
  margin: 20px auto;
}
</style>
